
import confetti from 'canvas-confetti';
import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { getBillingPortalUri, getCheckoutUri } from '@/services/steamcord';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';

function redirect(uri: string) {
  window.location.href = uri;
}

export default defineComponent({
  computed: {
    ...mapState(useOrgStore, ['currentOrg']),
    ...mapWritableState(useMainStore, {
      appLoading: 'loading',
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async redirectToCheckout() {
      if (this.loading || !this.currentOrg) {
        return;
      }

      this.loading = true;
      redirect(await getCheckoutUri(this.currentOrg.id));
    },
    async redirectToBillingPortal() {
      if (this.loading || !this.currentOrg) {
        return;
      }

      this.loading = true;
      redirect(await getBillingPortalUri(this.currentOrg.id));
    },
  },
  mounted() {
    this.appLoading = false;

    if (this.$route.query.success !== null) {
      return;
    }

    this.$router.replace({ params: undefined });

    let i = 0;
    const showConfetti = () => {
      if (i >= 5) {
        return;
      }

      confetti({
        particleCount: 200,
        spread: 160,
        origin: {
          x: Math.random(),
          y: Math.random(),
        },
      });

      setTimeout(showConfetti, 2000);

      i += 1;
    };

    setTimeout(showConfetti, 1000);
  },
});
